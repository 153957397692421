import { useContext, useEffect, useState } from "react";

import { CoreContext } from "context/CoreContext";
import {
  ButtonWrapper,
  CardExpandContainer,
  CardExpandContainerBody,
  CardExpandContainerHeader,
  CardExpandContainerHeaderContent,
  ModalContainerHeaderButton,
  ModalContainerHeaderButtonIcon,
} from "./styled";

import Button from "components/Form/Button";
import Input from "components/Form/Input";
import SuccessToast from "components/Toasts/Success";
import { Update } from "services/campaigns";

export default function CardAddScript({ closeable, campaign, reload }) {
  const [link, setLink] = useState("");
  const [title, setTitle] = useState("");

  const [sending, setSending] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const { user } = useContext(CoreContext);
  const action = async () => {
    if (disabled) {
      return;
    }

    setSending(true);

    const id = crypto.randomUUID();
    const script = { id, link, title, createdAt: new Date(), createBy: user };

    await Update(
      { data: { scripts: { ...campaign.scripts, [script.id]: script } } },
      campaign.id
    );

    SuccessToast({ message: "Roteiro adicionado com sucesso" });

    closeable();

    setSending(false);

    await new Promise((resolve) => setTimeout(resolve, 2000));

    reload();
  };

  useEffect(() => {
    if (!title || !link) {
      setDisabled(true);
      return;
    }

    setDisabled(false);
  }, [title, link]);

  return (
    <CardExpandContainer closeable={closeable}>
      <CardExpandContainerHeader>
        <CardExpandContainerHeaderContent></CardExpandContainerHeaderContent>
        {closeable ? (
          <ModalContainerHeaderButton>
            <Button outline nospace onClick={closeable}>
              <ModalContainerHeaderButtonIcon icon={"close"} />
            </Button>
          </ModalContainerHeaderButton>
        ) : null}
      </CardExpandContainerHeader>
      <CardExpandContainerBody>
        <h4>Adicionar roteiro</h4>
        <div>
          <Input
            label={"Link do roteiro  *"}
            placeholder="Adicione o link do documento no google drive"
            value={link}
            onChange={(e) => setLink(e?.target?.value)}
          />
          <Input
            label={"Título do roteiro  *"}
            placeholder="Adicione o título do roteiro"
            value={title}
            onChange={(e) => setTitle(e?.target?.value)}
          />
          <ButtonWrapper disabled={disabled}>
            <Button
              primary
              loading={sending}
              onClick={action}
              marginRight={"12px"}
            >
              ADICIONAR ROTEIRO
            </Button>
          </ButtonWrapper>
        </div>
      </CardExpandContainerBody>
    </CardExpandContainer>
  );
}
