import styled from "styled-components";

export const ModalContainer = styled.div.attrs({})`
  position: absolute;
  inset: 0 0 0 0;
  background: ${(props) => props.theme.palette.colors.darkshadow};
  z-index: 4;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Header = styled.div.attrs({})`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin-top: 16px;

  & > h1 {
    font-weight: 500;
    font-size: 24px;
    line-height: 28.64px;
  };

  & > span {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: ${(props) => props.theme.palette.secondary.main};
    cursor: pointer;
  }
`;

export const StatusLabelContainer = styled.div.attrs({})`
  weight: 400;
  font-size: 14px;
  line-height: 21px;

  display: flex;
  flex-direction: column;
  gap: 8px;

  margin-top: 16px;

  & > span {
    color: ${(props) => props.theme.palette.colors.black};
  }
`;

export const FilterContainer = styled.div.attrs({})`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  gap: 16px;
`;

export const Filter = styled.div.attrs({})`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const LabelContainer = styled.div.attrs({})`
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 8px;
`;

export const Label = styled.div.attrs({})`
  padding: 8px 16px;
  border: 1px solid ${(props) => props.theme.palette.colors.black};
  border-radius: 32px;

  color:${(props) => props.theme.palette.colors[props.actived ? "white" : "black"]};

  weight: 400;
  font-size: 14px;
  line-height: 16.71px;

  cursor: pointer;

  ${(props) =>
    props.actived &&
    `
    background-color: ${props.theme.palette.colors.black};
  `}
`;
