import { Grid, Typography } from "@mui/material";
import { CoreContext } from "context/CoreContext";
import moment from "moment";
import { useContext } from "react";
import { theme } from "ui/theme-color";
import { parseStrapiImage } from "utils";
import CardApproved from "../CardApproved";
import {
  AssetsContent,
  AssetsContentItemAction,
  AssetsContentItemActionIcon,
  AssetsContentItemActionIconDownload,
  AssetsContentItemText,
  AssetsContentItemViewIcon,
  UploadLabel,
} from "./styled";

export default function CardAssets({
  campaign,
  title,
  removable,
  showableAssets,
  reload,
  removeAsset: removeAssetUp,
  toReview = false,
}) {
  const { assets, reloading, setModal, user } = useContext(CoreContext);
  console.log(showableAssets);
  return !(showableAssets || assets)?.length ? null : (
    <>
      {!title ? null : <UploadLabel>{title}</UploadLabel>}

      <AssetsContent>
        {reloading
          ? null
          : (showableAssets || assets)?.map((item, key) => (
              <Grid
                container
                key={key}
                sx={{
                  alignItems: "center",
                  textAlign: "left",
                  padding: "8px",
                  borderBottom: `1px solid ${theme.palette.colors.borderline}`,
                }}
              >
                <Grid
                  item
                  xs={6}
                  md={4}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <img
                    src={
                      [".pdf", ".docx", ".xlsx", ".csv"].includes(
                        item.file?.ext
                      )
                        ? "/icons/document.svg"
                        : item.file?.previewUrl || item.file?.url
                    }
                    width="38px"
                    height="38px"
                    style={{ borderRadius: "12px" }}
                  />

                  <AssetsContentItemText>
                    {item?.file?.name}
                  </AssetsContentItemText>
                </Grid>
                <Grid item xs={0} md={4}>
                  {item.uploadAt != null && (
                    <Typography
                      display={{ xs: "none", md: "block" }}
                      style={{
                        margin: 0,
                        fontSize: "12px",
                        marginLeft: "5px",
                      }}
                    >
                      Enviado em: {moment(item.uploadAt).format("DD/MM/YYYY")}{" "}
                      Por {item?.uploadBy?.data?.attributes?.name || " "}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={6} md={4}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    {toReview ? (
                      item?.approved === undefined ||
                      item?.approved === null ? (
                        <div
                          style={{
                            fontWeight: 500,
                            color: "#5339E3",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            setModal({
                              type: "review",
                              item: {
                                ...item,
                                campaignId: campaign.id,
                                ratedBy: user.id,
                                ratedAt: new Date(),
                              },
                              reload,
                            })
                          }
                        >
                          Avaliar entregável
                        </div>
                      ) : (
                        <CardApproved approved={item?.approved} />
                      )
                    ) : null}
                    {item.approved !== null && item.approved !== undefined && (
                      <Typography
                        display={{ xs: "none", md: "block" }}
                        style={{
                          margin: 0,
                          fontSize: "12px",
                          marginLeft: "5px",
                        }}
                      >
                        Por {item?.ratedBy?.data?.attributes?.name || " "} às{" "}
                        {moment(item.approvedAt).format("DD/MM/YYYY")}
                      </Typography>
                    )}
                    <AssetsContentItemAction
                      onClick={() =>
                        !item?.file?.url
                          ? null
                          : window.open(
                              parseStrapiImage(item?.file?.url),
                              "campaing-asset"
                            )
                      }
                    >
                      <AssetsContentItemActionIconDownload />
                    </AssetsContentItemAction>
                    <AssetsContentItemAction
                      onClick={() => {
                        setModal({
                          type: "chat",
                          item: {
                            ...item,
                            campaignId: campaign.id,
                          },
                          reload,
                        });
                      }}
                    >
                      <AssetsContentItemViewIcon />
                    </AssetsContentItemAction>
                    {!removable && !removeAssetUp ? null : (
                      <AssetsContentItemAction
                        onClick={() => {
                          setModal({
                            type: "delete-delivery",
                            item: {
                              ...item,
                              campaignId: campaign.id,
                            },
                            reload,
                            removeAsset: removeAssetUp,
                          });
                        }}
                      >
                        <AssetsContentItemActionIcon />
                      </AssetsContentItemAction>
                    )}
                  </div>
                </Grid>
              </Grid>
            ))}
      </AssetsContent>
    </>
  );
}
