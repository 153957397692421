import React from "react";
import { useHistory } from "react-router-dom";

import {
  PageHeaderContent,
  PageHeaderContentTop,
  PageHeaderContentTopIcon,
  PageHeaderContentTopTitle,
  PageBreadcrumbs,
  PageBreadcrumbsText,
  PageBreadcrumbsSeparator,
  PageHeaderContentTopIconContainer,
} from "./styled";

export default function CampaingHeaderBread({
  title,
  children,
  bread,
  onBack,
}) {
  const history = useHistory();

  return (
    <>
      <PageHeaderContent>
        <PageHeaderContentTop>
          <PageHeaderContentTopIconContainer
            onClick={() => (onBack ? onBack() : history.goBack())}
          >
            <PageHeaderContentTopIcon />
          </PageHeaderContentTopIconContainer>
          <PageHeaderContentTopTitle>{title}</PageHeaderContentTopTitle>
          {children}
        </PageHeaderContentTop>
      </PageHeaderContent>

      <PageBreadcrumbs>
        {bread?.map((it, k, array) => (
          <>
            <PageBreadcrumbsText current={k + 1 === array.length}>
              {" "}
              {it}{" "}
            </PageBreadcrumbsText>
            {k + 1 < bread?.length ? (
              <PageBreadcrumbsSeparator> / </PageBreadcrumbsSeparator>
            ) : null}
          </>
        ))}
      </PageBreadcrumbs>
    </>
  );
}
