import { useContext } from "react";

import { useHistory } from "react-router-dom";

import {
  DashboardMenu,
  DashboardMenuContainer,
  SidemenuItem,
  SidemenuItemIcon,
  SidemenuItemText,
} from "./styled";

import { CoreContext } from "context/CoreContext";
import { DoLogout } from "services/authentication";

export default function DashboardSide({ opened, setOpened, active }) {
  const history = useHistory();
  const navigate = (to) => {
    console.log(history.location.pathname);
    if (
      history.location.pathname.startsWith("/dashboard/campaing/create/") &&
      !history.location.pathname.endsWith("/")
    ) {
      setModal({
        type: "cancel-campaign",
        back: () => history.push(`/${to}`),
        cleanForm,
      });
    } else {
      history.push(`/${to}`);
    }
  };

  const cleanForm = () => {
    setForm({});
    setAssets([]);
  };

  const { user, setModal, setForm, setAssets } = useContext(CoreContext);

  const verifyClose = (e) => {
    if (!e.target.closest(".menu-contant")) {
      setOpened(false);
    }
  };

  const sideOptions = [
    { title: "Home", icon: "menu1", action: () => navigate("dashboard") },
    {
      title: "Campanhas",
      icon: "menu2",
      action: () => navigate("dashboard/campaing"),
    },
    {
      title: "Usuários",
      icon: "menu4",
      action: () => navigate("dashboard/users"),
    },
  ];

  const filterOptionsByRole = (side) => {
    if (side.title === "Usuários" && user?.role?.name !== "Admin Master") {
      return false;
    }

    return true;
  };

  const exit = async () => {
    await DoLogout();
    navigate("login");
  };

  return (
    <>
      <DashboardMenuContainer onClick={verifyClose}>
        <DashboardMenu>
          {sideOptions?.filter(filterOptionsByRole).map((item, key) => (
            <SidemenuItem
              key={key}
              active={key === active}
              onClick={item.action}
            >
              <SidemenuItemIcon active={key === active} icon={item?.icon} />
              <SidemenuItemText active={key === active}>
                {item?.title}
              </SidemenuItemText>
            </SidemenuItem>
          ))}
        </DashboardMenu>
      </DashboardMenuContainer>
    </>
  );
}
