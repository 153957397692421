import { useContext, useRef, useState } from "react";

import {
  ContainerVideo,
  ContainerVideoAction,
  ContainerVideoHeader,
  ContainerVideoHeaderButtonIcon,
  ContainerVideoVideo,
} from "./styled";

import CardChatSupport from "components/Cards/ChatSupport";
import { Col, Row } from "reactstrap";
import ModalWrapper from "../Wrapper";

import Button from "components/Form/Button";
import ErrorToast from "components/Toasts/Error";
import SuccessToast from "components/Toasts/Success";
import { CoreContext } from "context/CoreContext";
import { Update } from "services/deliveries";
import { parseStrapiImage } from "utils";

export default function ModalReviewApprove() {
  const { modal, setModal, user } = useContext(CoreContext);

  const [approving, setApproving] = useState(false);
  const [refusing, setRefusing] = useState(false);

  const close = () => {
    setModal(null);
  };

  const reviewOptions = {
    approve: () => approveDelivable(),
    refuse: () => refuseDelivable(),
  };

  const approveDelivable = async () => {
    if (modal?.item?.id) {
      setApproving(true);
      const result = await Update(
        {
          data: { approved: true, ratedAt: new Date(), ratedBy: user.id },
        },
        modal?.item?.id
      );
      if (result?.data?.id) {
        SuccessToast({ message: `${modal?.item?.file?.name} aprovado` });

        if (typeof modal?.reload === "function") {
          modal?.reload();
        }
      }
      setApproving(false);
      close();
    }
  };

  const refuseDelivable = async () => {
    if (modal?.item?.id) {
      setRefusing(true);
      const result = await Update(
        {
          data: { approved: false, ratedAt: new Date(), ratedBy: user.id },
        },
        modal?.item?.id
      );
      if (result?.data?.id) {
        ErrorToast({ message: `${modal?.item?.file?.name} reprovado` });

        if (typeof modal?.reload === "function") {
          modal?.reload();
        }
      }
      setRefusing(false);
      close();
    }
  };

  const videoRef = useRef(null);

  return (
    <>
      <ModalWrapper
        approving={approving}
        refusing={refusing}
        review={reviewOptions}
      >
        <Row>
          <Col className="no-col">
            <ContainerVideo image={modal?.item?.file?.url}>
              <ContainerVideoHeader>
                <ContainerVideoAction>
                  <Button
                    nospace
                    primary
                    onClick={
                      modal?.item?.file?.url
                        ? () =>
                            window.open(
                              parseStrapiImage(modal?.item?.file?.url),
                              "campaing-asset"
                            )
                        : null
                    }
                    marginLeft={"12px"}
                  >
                    <ContainerVideoHeaderButtonIcon
                      icon={"cloud-download-white"}
                    />
                    {modal?.item?.isVideo ? `DOWNLOAD DO VÍDEO` : `DOWNLOAD`}
                  </Button>
                </ContainerVideoAction>
              </ContainerVideoHeader>

              {modal?.item?.isVideo ? (
                <>
                  <ContainerVideoVideo ref={videoRef} controls>
                    <source
                      src={parseStrapiImage(modal?.item?.file?.url)}
                      type="video/mp4"
                    />
                  </ContainerVideoVideo>
                </>
              ) : null}

              {/* <ContainerVideoBody>

                                <ContainerVideoBodyAction>
                                    <ContainerVideoBodyActionIcon icon={"play"} />
                                </ContainerVideoBodyAction>

                            </ContainerVideoBody>
                            <ContainerVideoFooter>

                                <ContainerVideoFooterProgress>
                                    <ContainerVideoFooterProgressText>0:10</ContainerVideoFooterProgressText>
                                    <ContainerVideoFooterProgressLine>
                                        <ContainerVideoFooterProgressLineDecoration />
                                        <ContainerVideoFooterProgressLineIndicator />
                                    </ContainerVideoFooterProgressLine>
                                    <ContainerVideoFooterProgressText>1:10</ContainerVideoFooterProgressText>
                                </ContainerVideoFooterProgress>

                            </ContainerVideoFooter> */}
            </ContainerVideo>
          </Col>
          <Col className="no-col">
            <CardChatSupport closeable={close} item={modal?.item} />
          </Col>
        </Row>
      </ModalWrapper>
    </>
  );
}
