import React, { useContext } from "react";
import { ThemedComponent } from "ui/theme";
import ModalReviewApprove from "./ReviewApprove";
import { CoreContext } from "context/CoreContext";
import ModalCreators from "./Creators";
import ModalDeleteCreator from "./DeleteCreator";
import ModalDeleteCampaign from "./DeleteCampaign";
import ModalDetailCreator from "./DetailCreator";
import ModalScript from "./Script";
import ModalDeleteScript from "./DeleteScript";
import ModalApproveScript from "./ApproveScript";
import ModalReproveScript from "./ReproveScript";
import ModalAddDelivery from "./AddDelivery";
import ModalChat from "./Chat";
import ModalDeleteDelivery from "./DeleteDelivery";
import ModalDeleteUser from "./DeleteUser";
import ModalAddUser from "./AddUser";
import ModalEditUser from "./EditUser";
import ModalMedia from "./Media";
import ModalCancelCampaign from "./CancelCampaign";
import ModalFilterCampaign from "./FilterCampaign";

export default function ModalController() {
  const { modal } = useContext(CoreContext);
  return (
    <>
      <ThemedComponent>
        {modal?.type === "filter-campaign" ? <ModalFilterCampaign /> : null}
        {modal?.type === "review" ? <ModalReviewApprove /> : null}
        {modal?.type === "creators" ? <ModalCreators /> : null}
        {modal?.type === "delete-creator" ? <ModalDeleteCreator /> : null}
        {modal?.type === "delete-campaign" ? <ModalDeleteCampaign /> : null}
        {modal?.type === "cancel-campaign" ? <ModalCancelCampaign /> : null}
        {modal?.type === "detail-creator" ? <ModalDetailCreator /> : null}
        {modal?.type === "add-script" ? <ModalScript /> : null}
        {modal?.type === "delete-script" ? <ModalDeleteScript /> : null}
        {modal?.type === "approve-script" ? <ModalApproveScript /> : null}
        {modal?.type === "reprove-script" ? <ModalReproveScript /> : null}
        {modal?.type === "add-delivery" ? <ModalAddDelivery /> : null}
        {modal?.type === "delete-delivery" ? <ModalDeleteDelivery /> : null}
        {modal?.type === "chat" ? <ModalChat /> : null}
        {modal?.type === "delete-user" ? <ModalDeleteUser /> : null}
        {modal?.type === "add-user" ? <ModalAddUser /> : null}
        {modal?.type === "edit-user" ? <ModalEditUser /> : null}
        {modal?.type === "media" ? <ModalMedia /> : null}
      </ThemedComponent>
    </>
  );
}
