import * as React from 'react';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';


export default function ComboBox({ list, value, onChange }) {
    return (
      <Stack spacing={3} sx={{ width: "100%" }}>
        <Autocomplete
          multiple
          id="tags-standard"
          options={list}
          value={value}
          onChange={onChange}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Escolha os usuários responsáveis pela campanha"
              placeholder=""
            />
          )}
        />
      </Stack>
    );
  }
