import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { CoreContext } from "context/CoreContext";

import {
  CardHomeContent,
  CardHomeContentLabel,
  CardHomeContentLabelDecoration,
  CardHomeContentLabelText,
  CardHomeContentBody,
  CardHomeContentBodyTitle,
  CardHomeContentBodyText,
  CardHomeContentBodyAction,
  CardDashboardCampaignTrashIcon,
  CardDashboardCampaignUserImage,
  CardDashboardCampaignUserImageText,
  CardDashboardCampaignMoreDetails,
  CardDashboardCampaignActionContainer,
  CardDashboardCampaignDetailsContainer,
} from "./styled";

import { parseStrapiImage } from "utils";
import { parseInitialize } from "utils/parsers";
import moment from "moment/moment";

export default function CardDashboardCampaign({
  createdAt,
  label,
  title,
  createdBy,
  user,
  id,
  reload,
}) {
  const { setModal } = useContext(CoreContext);

  const history = useHistory();
  const navigate = (to) => history.push(`/${to}`);

  const createdAtDate = moment(createdAt);
  const dateFormatted = createdAtDate.format("L");
  const hoursFormatted = createdAtDate.format("LT");

  const username =
    createdBy?.data?.attributes?.firstName ||
    user?.data?.attributes?.name ||
    " ";
  const userImage = null;

  const deleteCampaign = async () => {
    setModal({
      type: "delete-campaign",
      item: { campaignId: id, campaignTitle: title },
      reload,
    });
  };

  return (
    <>
      <CardHomeContent>
        <CardHomeContentBody>
          <div style={{ display: "flex" }}>
            <CardDashboardCampaignUserImage
              image={userImage ? parseStrapiImage(userImage) : null}
            >
              <CardDashboardCampaignUserImageText>
                {userImage ? null : parseInitialize(username)}
              </CardDashboardCampaignUserImageText>
            </CardDashboardCampaignUserImage>
          </div>
          <CardDashboardCampaignDetailsContainer>
            <div>
              <CardHomeContentBodyTitle>{title}</CardHomeContentBodyTitle>
              <CardHomeContentBodyText>
                {`Data de criação: ${dateFormatted} às ${hoursFormatted} | Criado por: ${username}`}
              </CardHomeContentBodyText>
            </div>
            <CardDashboardCampaignActionContainer>
              <CardHomeContentLabel isSuccess={label === "Finalizada"}>
                <CardHomeContentLabelDecoration
                  isSuccess={label === "Finalizada"}
                />
                <CardHomeContentLabelText isSuccess={label === "Finalizada"}>
                  {label === "Andamento" ? "Em Andamento" : label}
                </CardHomeContentLabelText>
              </CardHomeContentLabel>
              <CardHomeContentBodyAction>
                <CardDashboardCampaignMoreDetails
                  onClick={() => navigate(`dashboard/campaing/details/${id}`)}
                >
                  Ver detalhes&nbsp;&nbsp;&gt;
                </CardDashboardCampaignMoreDetails>
                <CardDashboardCampaignTrashIcon onClick={deleteCampaign} />
              </CardHomeContentBodyAction>
            </CardDashboardCampaignActionContainer>
          </CardDashboardCampaignDetailsContainer>
        </CardHomeContentBody>
      </CardHomeContent>
    </>
  );
}
