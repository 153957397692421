import React from "react";
import { useHistory } from "react-router-dom";

import {
  CardHomeContent,
  CardHomeContentHeader,
  CardHomeContentLabel,
  CardHomeContentLabelDecoration,
  CardHomeContentLabelText,
  CardHomeContentBody,
  CardHomeContentBodyTitle,
  CardHomeContentBodyText,
  CardHomeContentBodyDate,
  CardHomeContentBodyActions,
  CardHomeContentBodyAction,
  CardHomeContentLabelType,
} from "./styled";

import Button from "components/Form/Button";

export default function CardHome({ label, title, text, date, id, campaing_type }) {
  const history = useHistory();
  const navigate = (to) => history.push(`/${to}`);

  const campaignLabels = {
    "pontual-campaing": "Campanha Pontual",
    "creative-ads": "Creative Ads",
    "video-commerce": "Vídeo Commerce",
    "always-on": "Always on",
  };

  return (
    <>
      <CardHomeContent>
        <CardHomeContentHeader>
          <CardHomeContentLabel isSuccess={label === "Finalizada"}>
            <CardHomeContentLabelDecoration />
            <CardHomeContentLabelText>{label}</CardHomeContentLabelText>
            
          </CardHomeContentLabel>
          <CardHomeContentLabelType>{campaignLabels[campaing_type] || "Tipo Desconhecido"}</CardHomeContentLabelType>
        </CardHomeContentHeader>
        <CardHomeContentBody>
          {!title ? (
            <CardHomeContentBodyTitle>&nbsp;</CardHomeContentBodyTitle>
          ) : (
            <CardHomeContentBodyTitle>{title} </CardHomeContentBodyTitle>
          )}
          {!text ? (
            <CardHomeContentBodyText>&nbsp;</CardHomeContentBodyText>
          ) : (
            <CardHomeContentBodyText>{text}</CardHomeContentBodyText>
          )}
          <CardHomeContentBodyActions>
            <CardHomeContentBodyAction>
              <Button
                outline
                primary
                onClick={() => navigate(`dashboard/campaing/details/${id}`)}
              >
                VER DETALHES
              </Button>
            </CardHomeContentBodyAction>
          </CardHomeContentBodyActions>
        </CardHomeContentBody>
      </CardHomeContent>
    </>
  );
}
