import React, { useState, useContext, useMemo } from "react";
import { CoreContext } from "context/CoreContext";

import { useHistory, useLocation } from "react-router-dom";
import {
  DashboardTitle,
  DashboardText,
  CardHomeContentBodyAction,
  BodyHeader,
  BodyHeaderInfo,
  ContentHeaderSearch,
  FilterContainer,
  CardDashboardCampaignContainer,
  ButtonContent,
  QtdFilter
} from "./styled";

import ContainerAuthenticated from "containers/Authenticated";
import { Row } from "reactstrap";
import Button from "components/Form/Button";
import EmptyPage from "components/EmptyPage";
import CardDashboardCampaign from "components/Cards/DashboardCampaign";
import CampaingTabs from "components/Dashboard/Campaing/Tabs";
import Input from "components/Form/Input";
import DatePicker, { setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

import useCampaing from "hooks/useCampaing";
import { Load } from "ui/styled";

import { ptBR } from "date-fns/locale/pt-BR";
setDefaultLocale(ptBR);

export default function DashboardCampaing() {
  const history = useHistory();
  const navigate = (to) => history.push(`/${to}`);

  const [active, setActive] = useState(0);
  const [nameFilter, setNameFilter] = useState("");

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const startDate = queryParams.get('startDate');
  const endDate = queryParams.get('endDate');
  const campaingType = queryParams.get('campaingType');
  const status = queryParams.get('status');

  const { campaings, empty, loading, reload } = useCampaing();

  const { setModal } = useContext(CoreContext);

  const tabs = [
    { title: "Todas", value: "Todas" },
    { title: "Em Andamento", value: "Andamento" },
    { title: "Finalizada", value: "Finalizada" },
  ];

  const filterTab = (item) => {
    return (
      tabs?.[active]?.title === "Todas" || tabs?.[active]?.value === item?.label
    );
  };

  const filterType = (item) => {
    if (!campaingType) {
      return true;
    }
    return (
      item.campaing_type === campaingType
    );
  };

  const filterStatus = (item) => {
    if (!status) {
      return true;
    }
    return (
      item.status === status
    );
  };

  const filterName = (item) => {
    if (!nameFilter || nameFilter === "") {
      return true;
    }

    return (
      Object.values(item).filter(
        (value) =>
          typeof value === "string" &&
          value.toLowerCase().includes(nameFilter.toLowerCase())
      ).length > 0
    );
  };

  const qtdFilter = useMemo(() => {
    return [startDate, endDate, campaingType, status].filter(Boolean).length
  }, [location])

  const filterDate = (item) => {
    const date = moment(item.createdAt).startOf("day");
    const initialDate = moment(startDate).isValid() ? moment(startDate).startOf("day") : null;
    const finalDate = moment(endDate).isValid() ? moment(endDate).startOf("day") : null;

    if (!initialDate && !finalDate) {
      return true;
    }

    if (initialDate && finalDate) {
      return date >= initialDate && date <= finalDate;
    }

    if (initialDate) {
      return date >= initialDate;
    }

    if (finalDate) {
      return date <= finalDate;
    }
  };

  const handleFilter = () => {
    setModal({
      type: "filter-campaign",
      item: {
        startDate,
        endDate,
        campaingType,
        status,
      },
      navigate,
      history,
      location
    })
  }

  return (
    <>
      <ContainerAuthenticated side={1}>
        {!loading ? null : <Load colored centred />}
        {!empty || loading ? null : <EmptyPage />}
        {empty ? null : (
          <>
            <BodyHeader>
              <BodyHeaderInfo>
                <DashboardTitle small={!empty}> Campanhas </DashboardTitle>
                <DashboardText>
                  {" "}
                  Acompanhe as campanhas dos clientes e envie materiais para
                  aprovação.{" "}
                </DashboardText>
              </BodyHeaderInfo>
              <CardHomeContentBodyAction>
                <Button
                  onClick={() => navigate("dashboard/campaing/create")}
                  primary
                >
                  + CRIAR CAMPANHA
                </Button>
              </CardHomeContentBodyAction>
            </BodyHeader>
            <CampaingTabs tabs={tabs} active={active} setActive={setActive} />

            <FilterContainer
            >
              <ContentHeaderSearch>
                <Input
                  withSearch
                  rounded
                  placeholder="Buscar campanha"
                  value={nameFilter}
                  onChange={(e) => setNameFilter(e.target.value)}
                />
              </ContentHeaderSearch>

              <Button
                onClick={handleFilter}
                nospace
                outline
                primary
                sx={{
                  height: 49,
                  width: 122,
                  borderRadius: 32
                }}
              >
                <ButtonContent>
                  {!!qtdFilter && <QtdFilter>
                    {qtdFilter}
                  </QtdFilter>}
                  <span>
                    FILTRAR
                  </span>
                </ButtonContent>
              </Button>
            </FilterContainer>

            <CardDashboardCampaignContainer>
              {campaings
                ?.filter(filterTab)
                ?.filter(filterType)
                ?.filter(filterStatus)
                ?.filter(filterName)
                ?.filter(filterDate)
                ?.map((item) => (
                  <CardDashboardCampaign {...item} reload={reload} />
                ))}
            </CardDashboardCampaignContainer>
          </>
        )}
      </ContainerAuthenticated>
    </>
  );
}
