import React, { useState } from "react";
import PropTypes from "prop-types";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";

import { InputLabelOut, MaterialSelect } from "./styled";
import { ThemedComponent } from "ui/theme";

export const Select = ({
  options,
  small,
  value,
  onChange,
  id,
  secondary,
  label,
  labelPadding,
  labelMargin,
  backgroundColor,
  placeholder,
  labelSize,
  labelColor,
}) => {
  const [opened, setOpened] = useState(false);

  const toggleOpen = () => {
    setOpened(!opened);
  };

  const optionAction = (item) => {
    if (onChange && typeof onChange === "function") {
      onChange(item.target.value);
    }
    toggleOpen();
  };

  return (
    <>
      <ThemedComponent>
        {!label ? null : (
          <InputLabelOut labelPadding={labelPadding} labelMargin={labelMargin} labelSize={labelSize} labelColor={labelColor}
          >
            {label}
          </InputLabelOut>
        )}

        <FormControl fullWidth>
          <MaterialSelect
            displayEmpty
            small={small}
            labelId={id}
            color={secondary ? "secondary" : "primary"}
            id={`select-${id}`}
            value={value}
            onChange={optionAction}
            backgroundColor={backgroundColor}
          >
            <MenuItem disabled value="">
              {placeholder ?? "Selecione"}
            </MenuItem>
            {options?.map((item, key) => (
              <MenuItem key={key} value={item.id}>
                {item?.title}
              </MenuItem>
            ))}
          </MaterialSelect>
        </FormControl>
      </ThemedComponent>
    </>
  );
};

Select.propTypes = {
  placeholder: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.object,
  small: PropTypes.bool,
  secondary: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

Select.defaultProps = {
  placeholder: "",
  options: [],
  value: undefined,
  small: false,
  secondary: false,
  onChange: undefined,
};

export default Select;
