import styled from "styled-components";

export const DashboardTitle = styled.div.attrs({})`
  font-size: 40px;
  color: ${(props) => props.theme.palette.colors.black};
  margin-bottom: 12px;

  ${(props) =>
    props.centred
      ? `
            text-align: center;
        `
      : ``}

  ${(props) =>
    props.small
      ? `
            font-size: 24px;
        `
      : ``}

    @media(max-width: 987px) {
    margin-top: 20px;
  }

  @media (max-width: 768px) {
    margin-top: 4px;
    margin-bottom: 8px;
  }
`;

export const CardDashboardCampaignContainer = styled.div.attrs({})`
  & > div:first-of-type {
    border-radius: 8px 8px 0px 0px;
  }

  & > div:last-of-type {
    border-radius: 0px 0px 8px 8px;
    border-bottom: none;
  }
`;

export const DashboardText = styled.div.attrs({})`
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props.theme.palette.colors.black};
  ${(props) =>
    props.centred
      ? `
    text-align: center;
    `
      : ``}

  @media (max-width: 800px) {
    font-size: 14px;
    color: ${(props) => props.theme.palette.colors.text};
  }
`;

export const BodyHeader = styled.div.attrs({})`
  padding: 0 0 6px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  @media (max-width: 768px) {
    align-items: center;
  }

  @media (max-width: 500px) {
    flex-direction: column;
  }
`;
export const BodyHeaderInfo = styled.div.attrs({})`
  flex: 1;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
`;

export const FilterContainer = styled.div.attrs({})`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin: 24px 0px;

  @media (max-width: 880px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const ContentDatePickerContainer = styled.div.attrs({})`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
  }

  @media (max-width: 500px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
  }
`;

export const CardHomeContentBodyAction = styled.div.attrs({})`
  @media (max-width: 500px) {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
`;

export const ButtonContent = styled.div.attrs({})`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

export const QtdFilter = styled.div.attrs({})`
  width: 23px;
  height: 22px;
  border-radius: 50%;
  background-color: ${p => p.theme.palette.colors.black};
  color: ${p => p.theme.palette.colors.white};

  display: flex;
  justify-content: center;
  align-items: center;

  weight: 400;
  font-size: 12px;
  line-height: 14.32px;
`;

export const ContentHeaderSearch = styled.div.attrs({})`
  position: relative;

  border-radius: 100px;

  max-width: 550px;
  width: 100%;

  @media (max-width: 752px) {
    margin: 0px auto 0;
  }

  background: ${(props) => props.theme.palette.colors.white};
`;
