import styled from "styled-components";

export const CardHomeContent = styled.div.attrs({})`
  width: 100%;
  height: 232px;
  margin: 16px 0 0;
  padding: 16px 24px;
  border-radius: 16px;
  background: ${(props) => props.theme.palette.colors.white};

  box-shadow: 0px 3px 6px ${(p) => p.theme.palette.colors.shadow};
`;
export const CardHomeContentHeader = styled.div.attrs({})`
  display: flex;
  justify-content: flex-start;
  padding: 0 0 12px;
`;
export const CardHomeContentLabel = styled.div.attrs({})`
  padding: 4px 16px 4px 8px;
  background: ${(props) =>
    props.isSuccess
      ? props.theme.palette.colors.green
      : props.theme.palette.colors.blue};
  border-radius: 32px;
  display: flex;
  align-items: center;
`;
export const CardHomeContentLabelDecoration = styled.div.attrs({})`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  margin: 0 8px 0 0;
  background: ${(props) => props.theme.palette.colors.white};
`;
export const CardHomeContentLabelText = styled.div.attrs({})`
  font-size: 14px;
  color: ${(props) => props.theme.palette.colors.white};
`;
export const CardHomeContentLabelType = styled.div.attrs({})`
  font-size: 14px;
  color: ${(props) => props.theme.palette.colors.black};
  background: ${(props) => props.theme.palette.colors.lightprimary};
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  margin-left: 16px;
`;
export const CardHomeContentBody = styled.div.attrs({})``;
export const CardHomeContentBodyTitle = styled.div.attrs({})`
  font-size: 18px;
  color: ${(props) => props.theme.palette.colors.black};
  display: -webkit-box;
  -webkit-line-clamp: 1; /* Define o limite de 2 linhas */
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
export const CardHomeContentBodyText = styled.div.attrs({})`
  font-size: 14px;
  color: ${(props) => props.theme.palette.colors.text};
  margin: 8px 0 0px;
  width: 90%;
  height: 45px;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Define o limite de 2 linhas */
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
export const CardHomeContentBodyDate = styled.div.attrs({})`
  font-size: 12px;
  color: ${(props) => props.theme.palette.colors.black};
  min-height: 22px;
`;

export const CardHomeContentBodyActions = styled.div.attrs({})`
  padding: 0px 0 0;
  margin-top: 16px;
  display: flex;
`;

export const CardHomeContentBodyAction = styled.div.attrs({})``;
