import React, { useContext, useState, useMemo } from "react";

import { ModalContainer, Label, LabelContainer, StatusLabelContainer, Header, FilterContainer, Filter } from "./styled";

import { CoreContext } from "context/CoreContext";
import Button from "components/Form/Button";
import { formatDatePtBr, formatDateUsa } from "utils/date";
import { theme } from "ui/theme-color"


import Select from "components/Form/Select";
import Input from "components/Form/Input";
import { campaingTypes } from "utils/mock";

const STATUS = [
  { text: "Em andamento", id: "progress" }, {
    text: "Finalizada",
    id: "finished",
  }
]

export default function ModalFilterCampaign() {
  const { modal, setModal } = useContext(CoreContext);
  const [loading, setLoading] = useState(false);

  const defaultFormState = {
    startDate: formatDatePtBr(modal?.item?.startDate) ?? "",
    endDate: formatDatePtBr(modal?.item?.endDate) ?? "",
    campaingType: modal?.item?.campaingType ?? "",
    status: modal?.item?.status ?? ""
  }

  const [form, setForm] = useState(() => defaultFormState);

  const clearForm = () => {
    setForm({
      startDate: "",
      endDate: "",
      campaingType: "",
      status: "",
    });
  }

  const close = () => {
    setModal(null);
  };

  const handleFilter = async () => {
    const { startDate, endDate, campaingType, status } = {
      startDate: formatDateUsa(formValue("startDate")),
      endDate: formatDateUsa(formValue("endDate")),
      campaingType: formValue("campaingType"),
      status: formValue("status"),
    };


    modal?.navigate(`dashboard/campaing?startDate=${startDate}&endDate=${endDate}&campaingType=${campaingType}&status=${status}`)

    close();
  };

  const formValue = (ref) => {
    return form?.[ref] ? form?.[ref] : "";
  };

  const changeForm = (value, ref) => {
    setForm({ ...form, [ref]: value });
  };

  const options = useMemo(() =>
    campaingTypes.map(campaing => {
      return {
        id: campaing.type,
        title: campaing.title,
      }
    }, [])
  )

  return (
    <>
      <ModalContainer>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            width: "480px",
            height: "fit-content",
            background: "rgba(255, 255, 255, 1)",
            borderRadius: "8px",
            padding: "16px",
          }}
        >

          <img
            src="/icons/close.svg"
            width={"10px"}
            height={"10px"}
            style={{ alignSelf: "end", cursor: "pointer" }}
            onClick={close}
          />

          <Header>
            <h1>Filtrar campanha</h1>

            <span onClick={clearForm}>limpar</span>
          </Header>


          <Select
            label={"Nome da campanha"}
            placeholder="Selecione a campanha"
            options={options}
            value={formValue("campaingType")}
            labelPadding={"16px 0 8px 0;"}
            labelSize={"14px"}
            labelColor={theme.palette.colors.black}
            onChange={(val) => { changeForm(val, "campaingType") }}
          />

          <FilterContainer>
            <Filter>
              <Input
                type={"date"}
                label={"Data inicial"}
                placeholder=""
                value={formatDateUsa(formValue("startDate"))}
                onChange={(e) =>
                  changeForm(formatDatePtBr(e.target.value), "startDate")
                }
                labelPadding={"16px 0 8px 0;"}
                labelSize={"14px"}
                labelColor={theme.palette.colors.black}
              />
            </Filter>
            <Filter>
              <Input
                type={"date"}
                label={"Data final"}
                placeholder=""
                value={formatDateUsa(formValue("endDate"))}
                onChange={(e) =>
                  changeForm(formatDatePtBr(e.target.value), "endDate")
                }
                labelPadding={"16px 0 8px 0;"}
                labelSize={"14px"}
                labelColor={theme.palette.colors.black}
              />
            </Filter>
          </FilterContainer>

          <StatusLabelContainer>
            <span>Status</span>

            <LabelContainer>

              {
                STATUS.map(status =>
                  <Label
                    onClick={() => changeForm(status.id, "status")}
                    key={status.id} actived={form.status === status.id}>
                    {status.text}
                  </Label>
                )
              }
            </LabelContainer>
          </StatusLabelContainer>

          <div style={{ display: "flex", gap: "8px", width: "100%" }}>
            <Button
              primary
              onClick={handleFilter}
              loading={loading}
              minWidth={"fit-content"}
              sx={{ height: 48 }}
            >
              VER RESULTADOS
            </Button>
          </div>
        </div>
      </ModalContainer>
    </>
  );
}
