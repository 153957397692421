import styledCmp from "styled-components";

import { styled } from "@mui/material/styles";
import Input from "@mui/material/OutlinedInput";
import { TextField } from "@mui/material";


export const MaterialInput = styled(Input)(
  ({ theme, rounded, type, small }) => ({
    borderRadius: rounded ? 32 : 8,
    height: type === "textarea" ? (small ? 69 : 150) : "auto",
  })
);

export const MaterialTextField = styled(TextField)(({ theme, rounded, type, small }) => ({
  borderRadius: rounded ? 32 : 8,
  "& .MuiInputBase-root": {
    height: small ? "69px" : "150px", // Define a altura total do input
  },

  "& .MuiOutlinedInput-input": {
    height: "100% !important", // Garante que o textarea interno ocupe toda a altura
  },
}));

export const InputIcon = styledCmp.img.attrs({})`
`;

export const InputLabelOut = styledCmp.div.attrs({})`
    display: flex;
    flex-direction: arrow;
    gap: 4px;
    padding: ${(p) => (p.labelPadding ? p.labelPadding : "24px 0 8px;")}
    color: ${(p) => p.theme.palette.colors.text};
    ${(p) => (p.labelMargin ? `margin: ${p.labelMargin}` : "")}
    
    ${(p) => (p.labelSize &&
    `
        font-size: ${p.labelSize};
      `
  )}

      ${(p) => (p.labelColor &&
    `
        color: ${p.labelColor};
      `
  )}

    ${(p) =>
    p.hasAsterisk &&
    `
      &::after {
        content: "*";
        color: red;
      }
    `}
`;
